export default function useParamsToQuery(params: object): string {
    const searchParams = new URLSearchParams()

    Object.entries(params).map((entry: any[]) => {
        if (Array.isArray(entry[1]) && entry[0] !== 'include') {
            entry[1].map((value: string) => {
                searchParams.append(`${entry[0]}${!entry[0].endsWith('[]') ? '[]' : ''}`, `${value}`)
            })
        } else if (entry[1] !== null) {
            searchParams.append(entry[0], `${entry[1]}`)
        }
    })

    return searchParams.toString()
}
